import React, { setGlobal, getGlobal } from 'reactn'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './index.scss'

const mobileWidth = 728

function resize () {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

setGlobal({
  isMobile: window.innerWidth <= mobileWidth,
  contentModal: null,
  experienceModal: null,
  experienceModalSide: 'left',
  experienceModalSelectedObject: null,
  menuStatus: false,
  shareStatus: false,
}).then(() => ReactDOM.render(<App />, document.getElementById('root')))

window.addEventListener('resize', () => {
  resize()
  const isMobile = window.innerWidth <= mobileWidth
  if (getGlobal().isMobile !== isMobile) {
    setGlobal({ isMobile: window.innerWidth <= mobileWidth })
  }
})

serviceWorker.unregister()
resize()