import React from 'reactn'
import CanvasOverlay from '../OpenSeadragonCanvasOverlay'
import { isMobile } from 'react-device-detect'
import MipMappedObjectCache from '../MipMappedObjectCache'
import Objects from '../Objects'
import './DeepZoom.scss'
import { objectExpression } from '@babel/types';

const mipmapLevels = 4
const mipmapBoundaryThreshold = 0

const objects = Objects

objects.forEach(object => MipMappedObjectCache.loadImage(object.id, mipmapLevels))
objects.forEach(object => MipMappedObjectCache.loadImage(object.id, 2, true))

class DeepZoom extends React.Component {
  constructor (props) {
    super(props)
    this.lastPoint = { x: -9999, y: -9999, zoom: 1, time: 0 }
    this.props = props
  }

  componentDidMount () {
    this.load()
  }

  componentWillUnmount () {
    this.__unmounted = true
    this.osd.destroy()
  }

  load = () => {
    if (this.osd) {
      this.osd.destroy()
    }
    this.osd = new window.OpenSeadragon({
      element: this.container,
      tileSources: 'painting.dzi',
      visibilityRatio: 1,
      minZoomImageRatio: 1,
      // showNavigator: true,
      homeFillsViewer: true,
      showZoomControl: true,
      zoomInButton: "zoom-in",
      zoomOutButton: "zoom-out",
      showHomeControl: false,
      showFullPageControl: false,
      animationTime: 0.8,
      clickTimeThreshold: Infinity,
      gestureSettingsMouse: {
        scrollToZoom: true,
        clickToZoom: false,
        pinchToZoom: true,
        zoomToRefPoint: true,
        flickEnabled: false,
      },
      gestureSettingsTouch: {
        scrollToZoom: true,
        clickToZoom: false,
        pinchToZoom: true,
        zoomToRefPoint: true,
        flickEnabled: false,
      },
      gestureSettingsUnknown: {
        scrollToZoom: true,
        clickToZoom: false,
        pinchToZoom: true,
        zoomToRefPoint: true,
        flickEnabled: false,
      }
    })
    this.overlay = new CanvasOverlay(this.osd, { onRedraw: this.draw })
    this.mouseTracker = new window.OpenSeadragon.MouseTracker({
      element: this.osd.container,
      moveHandler: e => {
        if (!isMobile) {
          const webPoint = e.position
          const viewportPoint = this.osd.viewport.pointFromPixel(webPoint)
          const imagePoint = this.osd.viewport.viewportToImageCoordinates(viewportPoint)
          const zoom = this.osd.viewport.getZoom(true)
          const imageZoom = this.osd.viewport.viewportToImageZoom(zoom)
          this.handlePointHover({ ...imagePoint, zoom: imageZoom })
        }
      }
    })
    this.osd.addHandler('animation', this.onUpdate)
    this.osd.addHandler('canvas-click', this.onClick)
    this.osd.addHandler('canvas-press', this.onDrag)
    this.osd.addHandler('canvas-release', this.onDragEnd)
  }

  onClick = e => {
    if (e.quick) {
      const webPoint = e.position
      const viewportPoint = this.osd.viewport.pointFromPixel(webPoint)
      const imagePoint = this.osd.viewport.viewportToImageCoordinates(viewportPoint)
      this.onClickedPoint(imagePoint)
    }
  }

  getDistance = (object, point) => {
    const center = {
      x: object.x + object.width / 2,
      y: object.y + object.height / 2
    }
    return Math.sqrt(Math.pow(center.x - point.x, 2) + Math.pow(center.y - point.y, 2))
  }

  onClickedPoint = point => {
    const objectList = objects.filter(object => this.inBounds(object, point))
    const object = objectList.length && objectList.sort((a, b) => this.getDistance(a, point) - this.getDistance(b, point))[0]
    if (object) {
      const zoom = object.zoom || 3
      const left = object.x > this.osd.world.getItemAt(0).getContentSize().x / 2
      const coords = this.osd.viewport.imageToViewportCoordinates(object.x + object.width / 2, object.y + object.height / 2)
      coords.x += 1 / zoom / 4 * (left ? -1 : 1)
      if (!this.global.isMobile) {
        this.oldCoordinates = this.osd.viewport.getCenter(true)
        this.oldZoom = this.osd.viewport.getZoom(true)
        this.setGlobal({
          experienceModal: object.modal,
          experienceModalSide: left ? 'left' : 'right',
          experienceModalSelectedObject: object.id
        })
        this.osd.viewport.panTo(coords, window.innerWidth > 0)
        this.osd.viewport.zoomTo(zoom, null, window.innerWidth > 0)
      } else {
        this.setGlobal({
          experienceModal: object.modal,
          experienceModalSide: null,
          experienceModalSelectedObject: null
        })
      }
      window.transitionLock = true
      setTimeout(() => window.transitionLock = false)
    }
  }

  updateZoom = () => {
    if (!this.global.experienceModal && this.oldCoordinates) {
      this.osd.viewport.panTo(this.oldCoordinates, window.innerWidth > 0)
      this.osd.viewport.zoomTo(this.oldZoom, null, window.innerWidth > 0)
      this.oldCoordinates = null
    }
  }

  onUpdate = () => {
    // document.body.scrollTop = document.documentElement.scrollTop = 0
    if (isMobile) {
      const webPoint = this.osd.viewport.getCenter()
      const imagePoint = this.osd.viewport.viewportToImageCoordinates(webPoint)
      const zoom = this.osd.viewport.getZoom(true)
      const imageZoom = this.osd.viewport.viewportToImageZoom(zoom)
      this.handlePointHover({ ...imagePoint, zoom: imageZoom })
    }
  }

  onDrag = () => {
    this.container.dataset.grabbing = 'true'
  }

  onDragEnd = () => {
    this.container.dataset.grabbing = 'false'
  }

  handlePointHover = ({ x, y, zoom }) => {
    const time = Date.now()
    this.lastPoint = { x, y, zoom, time }
  }

  updateHits = () => {
    const time = Date.now()
    const objectList = objects.filter(object => this.inBounds(object, this.lastPoint))
    const object = objectList.length && objectList.sort((a, b) => this.getDistance(a, this.lastPoint) - this.getDistance(b, this.lastPoint))[0]
    if (object) {
      object.hoverTime = time
      this.container.dataset.hovering = 'true'
    } else {
      this.container.dataset.hovering = 'false'
    }
  }

  inBounds = (object, point) => {
    return (
      point.x > object.x &&
      point.y > object.y &&
      point.x < object.x + object.width &&
      point.y < object.y + object.height
    )
  }

  draw = opts => {
    this.updateZoom()
    this.updateHits()
    const context = opts.context
    const time = Date.now()
    this.drawOverlay(opts)
    const zoomLevel = this.osd.viewport.viewportToImageZoom(this.osd.viewport.getZoom(true))
    const mipmapLevel = (Math.min(Math.max(1, -Math.log2(zoomLevel) + 1), mipmapLevels) + mipmapBoundaryThreshold) | 0
    objects.forEach(object => {
      const t = Math.min(time - (object.hoverTime || 0), 500) / 500
      if (t < 1 || object.id === this.global.experienceModalSelectedObject) {
        const image = MipMappedObjectCache.getNearestImage(object.id, mipmapLevel, this.global.experienceModalSelectedObject !== null)
        // console.log(image.image)
        image.image.style.cursor = 'pointer'
        if (image) {
          if (!(this.global.experienceModalSelectedObject !== null && object.id !== this.global.experienceModalSelectedObject)) {
            context.save()
            context.globalAlpha = object.id === this.global.experienceModalSelectedObject ? 1 : (1 - t)
            const scale = 2 ** (image.level - 1)
            context.translate(object.x, object.y)
            context.scale(scale, scale)
            context.drawImage(image.image, 0, 0)
            context.restore()
          }
        } else {
        }
      } 
    })
  }

  drawOverlay = opts => {
    if (!!this.global.experienceModal && !this.global.isMobile) {
      const context = opts.context
      context.globalAlpha = 1
      context.fillStyle = 'rgba(0, 0, 0, 0.8)'
      const contentSize = this.osd.world.getItemAt(0).getContentSize()
      context.fillRect(-1, -1, contentSize.x + 2, contentSize.y + 2)
    }
  }

  render = props => (
    <div ref={r => this.container = r} data-overlay={(this.global.isMobile && !!this.global.experienceModal) ? true : undefined} className="deep-zoom"/>
  )
}

export default DeepZoom
