const mipmapLevels = 4

class MipMappedImageCache {
  images = new Map()
  altImages = new Map()

  loading = new Set()
  altLoading = new Set()

  getKey (id, level) {
    return `${level}-${id}`
  }

  loadImage (id, level) {
    if (!this.loading.has(this.getKey(id, level))) {
      const image = new Image()
      image.addEventListener('load', () => this.images.set(this.getKey(id, level), image))
      image.src = `objects/${level}/${id}.png`
      this.loading.add(this.getKey(id, level))
    }

    if (!this.altLoading.has(this.getKey(id, level))) {
      const image = new Image()
      image.addEventListener('load', () => this.altImages.set(this.getKey(id, level), image))
      image.src = `objects/${level}/${id}b.png`
      this.altLoading.add(this.getKey(id, level))
    }
  }

  getNearestImage (id, level, alt = false) {
    for (let i = level; i <= mipmapLevels; i++) {
      if (this[alt ? 'altImages' : 'images'].has(this.getKey(id, i))) {
        return {
          image: this[alt ? 'altImages' : 'images'].get(this.getKey(id, i)),
          level: i
        }
      } else {
        if (level === i) {
          this.loadImage(id, level)
        }
      }
    }
    return null
  }
}

export default new MipMappedImageCache()
