import React, { useGlobal } from 'reactn'
import Logo from '../assets/footer-logo.png'
import './Footer.scss'

function FooterItem (props) {
  return (
    <a href={props.link} className="item">{props.label}</a>
  )
}

function Footer () {
  const [ isMobile ] = useGlobal('isMobile')

  return (
    <div className="footer">
      <img alt="" className="logo" src={Logo}/>
      { !isMobile && <div className="items">
        <FooterItem label="2019 Clio Awards" link="https://clios.com/awards" />
        <FooterItem label="About" link="https://clios.com/about" />
        <FooterItem label="Terms of Use" link="https://clios.com/terms-use" />
        <FooterItem label="Privacy Policy" link="https://clios.com/privacy-policy" />
        <FooterItem label="Cookie Policy" link="https://clios.com/cookie-policy" />
        <FooterItem label="Contact" link="https://clios.com/contact-us" />
        <FooterItem label="Careers" link="https://www.linkedin.com/jobs/search/?f_C=10637912&locationId=OTHERS.worldwide&redirect=false" />
      </div> }
    </div>
  )
}

export default Footer
