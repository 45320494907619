import React, { useGlobal } from 'reactn'
import Header from './components/Header'
import Experience from './components/Experience'
import Content from './components/Content'
import Footer from './components/Footer'
import animateScrollTo from 'animated-scroll-to'
import './App.scss'

function App() {
  const [ experienceModal ] = useGlobal('experienceModal')
  function scroll () {
    animateScrollTo(document.querySelector('.content'))
  }

  return (
    <>
      <div className="app">
        <Header/>
        <Experience/>
        <div className="nav-down">
          <svg style={{ opacity: experienceModal ? 0.25 : 1 }} onClick={scroll} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#e8e3d9" d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/><path fill="none" d="M0 0h24v24H0V0z"/></svg>
        </div>
        <Content/>
        <Footer/>
      </div>
    </>
  )
}

export default App
