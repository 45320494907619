import React, { useEffect, useRef, useGlobal } from 'reactn'
import animateScrollTo from 'animated-scroll-to'
import CloseImage from '../assets/close.png'
import Plyr from 'plyr';
import ScrollArea from 'react-scrollbar';
import './Modal.scss'

function ContentPhotoModal(props) {
  return (
    <>
      <img className="close" src={CloseImage} onClick={props.onClose} alt="" />
      <div className="col">
        <div className="image" style={{ backgroundImage: `url(${props.content})` }} />
      </div>
      <ScrollArea className="col">
        <h3>{props.heading}</h3>
        <p dangerouslySetInnerHTML={{ __html: props.description }}></p>
      </ScrollArea>
    </>
  )
}

function ExperiencePhotoModal(props) {
  useEffect(() => {
    document.body.style.position = 'fixed'
    return (() => {
      document.body.style.position = 'static'
    })
  }, [])

  return (
    <>
      <img className="close" src={CloseImage} onClick={props.onClose} alt="" />
      <div className="col">
        <div className="image" style={{ backgroundImage: `url(${props.content})` }}/>
      </div>
      <ScrollArea className="col">
        <h3>{props.heading}</h3>
        <p dangerouslySetInnerHTML={{ __html: props.description }}></p>
      </ScrollArea>
    </>
  )
}

function ExperienceVideoModal (props) {

  const player = new Plyr('#player', {
    controls: ['fullscreen', 'play-large'],
    ratio: '16:9',
    youtube: {
      controls: 0,
    }
  });
  window.player = player;

  useEffect(() => {
    document.body.style.position = 'fixed'
    return (() => {
      document.body.style.position = 'static'
    })
  }, [])

  return (
    <>
      <img className="close" src={CloseImage} onClick={props.onClose} alt="" />
      <div className="col">
        <div id="player" data-plyr-provider="youtube" data-plyr-embed-id={props.content}></div>
      </div>
      <ScrollArea className="col">
        <h3>{props.heading}</h3>
        <p dangerouslySetInnerHTML={{ __html: props.description}}></p>
      </ScrollArea>
    </>
  )
}

function Modal (props) {
  const focus = useRef(null)
  const [ experienceModalSide ] = useGlobal('experienceModalSide')
  useEffect(() => animateScrollTo(focus.current), [])

  return (
    <div className="modal" data-type={props.type} data-side={experienceModalSide}>
      <div ref={focus} className="focus-marker"/>
      {
        (props.type === 'content-photo' && <ContentPhotoModal {...props} />) ||
        (props.type === 'experience-photo' && <ExperiencePhotoModal {...props} />) ||
        (props.type === 'experience-video' && <ExperienceVideoModal {...props} />)
      }
    </div>
  )
}

export default Modal
