
import React, { useGlobal, useState } from 'reactn'
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share'
import copy from 'clipboard-copy'
import './ShareMenu.scss'

function CopyLink(props) {
  return (
    <div className="copy-link-container">
      <input type="text" value="https://www.clio60.com/" id="copyLink" />
      <div className="social-share" onClick={props.function}>{props.label}</div>
    </div>
  )
}


function ShareMenu (props) {
  const [copyState, setCopyState] = useState(false)
  const shareUrl = 'https://www.clio60.com/'
  const description = "Discover more about the creatives and campaigns that made advertising history. #Clio60"
  const copyTitle = copyState ? 'Copied!' : 'Copy Link'

  function copyUrl() {
    copy('https://www.clio60.com/')
    setCopyState(true)
  }
  
  return (
    <div className="share-dropdown" data-expanded={props.open}>
      <FacebookShareButton
        url={shareUrl}
        quote={description}
        className="social-share"
      >
        Facebook
      </FacebookShareButton>
      <TwitterShareButton
        url={shareUrl}
        title={description}
        className="social-share"
      >
        Twitter
      </TwitterShareButton>
      <LinkedinShareButton
        url={shareUrl}
        className="social-share"
      >
        LinkedIn
      </LinkedinShareButton>
      <CopyLink label={copyTitle} function={copyUrl} />
    </div>
  )
}

export default ShareMenu