import React, { useGlobal } from 'reactn'
import ShareMenu from './ShareMenu'
import './MobileMenu.scss'

function MenuItem (props) {
  return (
    <span onClick={props.function}>{props.label}</span>
  )
}

function MenuLink (props) {
  return (
    <a href={props.link} target="_blank">{props.label}</a>
  )
}

function MobileMenu () {
  const [menuStatus, setMenuStatus] = useGlobal('menuStatus')
  const [shareStatus, setShareStatus] = useGlobal('shareStatus')

  function openMenu () {
    setMenuStatus(!menuStatus)
    if (shareStatus) {
      share()
    }
  }

  function share() {
    setShareStatus(!shareStatus)
  }

  return (
    <div className="mobile-menu">
      <div className="hamburger" onClick={openMenu}>
        <div className="dot"></div>
      </div>
        <div className="expanded-menu" data-expanded={menuStatus}>
          <div className="link-container">
            <MenuItem label="Share" function={share}/>
              <ShareMenu open={shareStatus} defaultValue={false} />
            <MenuLink label="Buy Tickets" link="http://bit.ly/2YNDv8I"/>
            <MenuLink label="Clios.com" link="http://clios.com"/>
          </div>
          <div className="border"></div>
          <div className="link-container">
            <MenuLink label="2019 Clio Awards" link="https://clios.com/"/>
            <MenuLink label="About" link="https://clios.com/about"/>
            <MenuLink label="Terms of Use" link="https://clios.com/terms-use"/>
            <MenuLink label="Privacy Policy" link="https://clios.com/privacy-policy"/>
            <MenuLink label="Cookie Policy" link="https://clios.com/cookie-policy"/>
            <MenuLink label="Contact" link="https://clios.com/contact-us"/>
            <MenuLink label="Careers" link="https://www.linkedin.com/jobs/search/?f_C=10637912&locationId=OTHERS.worldwide"/>
          </div>
        </div>
    </div>
  )
}

export default MobileMenu
