export default [
	{
		id: 1,
		x: 403,
		y: 104,
		width: 180,
		height: 179,
		modal: {
			type: 'experience-video',
			content: 'WXNEwKtgClk',
			heading: `The Guardian "3 Little Pigs"`,
			description: `In 2012, the Guardian newspaper launched its first campaign in 25 years with a retelling of the Three Little Pigs. Created by BBH London, the work showed the transformation of news in an increasingly digital world. Viewers were forced to question if the pigs were the victims of crime, or if they had, in fact, framed the wolf. Promoting the Guardian's philosophy of "Open Journalism," the ad urged readers to collaborate in the news process. The pig heads came from a Royal Ballet Company production of a children's ballet based on Beatrix Potter characters. "Three Little Pigs" ultimately won a Grand Clio, and the trio even appeared at the awards ceremony.`
		}
	},
	{
		id: 2,
		x: 645,
		y: 432,
		width: 123,
		height: 153,
		modal: {
			type: 'experience-video',
			content: 'SXB-z033b5E',
			heading: `Dumb Ways To Die`,
			description: `Dumb Ways to Die ranks as the most-shared PSA of all time. Created by McCann Melbourne for Metro Trains, the animated video debuted in November 2012 and quickly went viral, amassing a staggering 325 million YouTube views. Leveraging a catchy, dark-humored song and compellingly kooky visuals, the PSA illustrated that the dumbest way to die is getting hit by a train. "Dumb Ways" picked up four Gold Clios and generated a children's book, mobile game and website where 127 million people pledged to act sensibly and safely around trains.`
		}
	},
	{
		id: 3,
		x: 254,
		y: 852,
		width: 154,
		height: 405,
		modal: {
			type: 'experience-video',
			content: 'FLMnGWSCFQQ',
			heading: `Fearless Girl`,
			description: `"Fearless Girl," created by McCann New York for State Street Global Advisors, is a four-foot-tall bronze statue of a youngster who, when initially placed on Wall Street in March 2017, defiantly stared down the "Charging Bull." Installed for International Women's Day, she drew attention to the importance of female corporate leadership. She became an overnight sensation, cultural icon and tourist attraction. "Fearless Girl" won a staggering five Grand Clios in 2017. In late 2018 she was moved in front of the New York Stock Exchange, where she now resides permanently.`
		}
	},
	{
		id: 4,
		x: 607,
		y: 922,
		width: 102,
		height: 351,
		modal: {
			type: 'experience-video',
			content: '-1Xd1LCUPJ4',
			heading: `Barbie`,
			description: `Mattel's Barbie has been an icon since her debut in 1959. But through the decades, the brand received flak for its representation of women. In 2015, "Imagine the Possibilities" by BBDO reimagined the role this doll plays in kids' lives by showing that girls can be anything they put their minds to. The commercial scored a Clio Award. In the painting, Barbie is seen posing next to State Street Global Advisors' <i>Fearless Girl</i>.`
		}
	},
	{
		id: 5,
		x: 44,
		y: 1450,
		zoom: 2,
		width: 293,
		height: 709,
		modal: {
			type: 'experience-photo',
			content: 'content/sir-john-hegarty.jpg',
			heading: 'Sir John Hegarty',
			description: `Sir John Hegarty, co-founder of Bartle Bogle Hegarty (later BBH) in 1982, is recognized for high-profile Audi and Levi's campaigns, among many others. For Levi's, his shop devised the cheeky "Launderette" commercial in 1985, and the viral hit "Flat Eric," from the Clinton era, built around a fluffy yellow puppet created by Jim Henson of Muppets' fame. Hegarty landed a Clio Lifetime Achievement Award in 2005. Sir John is no stranger to award shows, and his presence is felt even when he chooses not to take center stage. This aspect of his demeanor has been portrayed in his positioning within the composition.`
		}
	},
	{
		id: 6,
		x: 255,
		y: 1615,
		width: 139,
		height: 179,
		modal: {
			type: 'experience-video',
			content: 'bajVo-Jt-Mg',
			heading: `Levi's Flat Eric`,
			description: `Flat Eric was an enigmatic yellow puppet from Levi's commercials of the late '90s. Created by Bartle Bogle Hegarty, Flat Eric and his human pals bob their heads to an electronic track and evade the police in this spot. The campaign's concepting phase included an idea for a commercial where Flat Eric would get run over by a car; the idea was tossed, but the name "Flat Eric" stuck. (The ads promoted Sta-Prest crease denim clothing.) The thumping soundtrack, "Flat Beat," was a smash on the U.K. charts. All in all, this campaign showed how a cool puppet could freshen up a heritage brand. Flat Eric is seen at the bar, sharing this celebration with one of his creators, Sir John Hegarty.`
		}
	},
	{
		id: 7,
		x: 381,
		y: 1364,
		width: 156,
		height: 177,
		modal: {
			type: 'experience-video',
			content: 'GySkpdoGhxw',
			heading: `FedEx "Fast Paced World"`,
			description: `FedEx's "Fast Paced World" spot was created by Ally & Gargano in 1981. The commercial featured John Moschitta, an actor known for his rapid speech delivery, who performed 29 flawless takes of the rapid-fire scene, prompting the director to comment, "He's like a machine." As a joke, Moschitta intentionally flubbed one line of the concluding take, a bit that appeared in the final cut. The commercial won six Clio Awards and earned this mile-a-minute man the nickname "motormouth."`
		}
	},
	{
		id: 8,
		x: 417,
		y: 1400,
		width: 296,
		height: 380,
		modal: {
			type: 'experience-video',
			content: 'a7LlmdSqrSw',
			heading: `Bud Knight`,
			description: `The Bud Knight stars in Bud Light's "Dilly Dilly" commercials from Wieden + Kennedy New York. The much-loved character was killed during a Super Bowl LIII crossover commercial with HBO's Game of Thrones—the spot won the 2019 Super Clio for the best ad in the Big Game. Miller Lite later produced a March Madness spot in which the character awakens and reaches for a can of (you guessed it) Miller Lite. Turns out, the Bud Knight's death was all in joust (heh), and Bud ultimately used mystical powers (and/or a copywriter) to resurrect the heavily armored hero.`
		}
	},
	{
		id: 9,
		x: 425,
		y: 1740,
		width: 242,
		height: 99,
		modal: {
			type: 'experience-photo',
			content: 'content/clio-free-for-all.jpg',
			heading: `Clios 1991 Incident`,
			description: `The 1991 Clio Awards are considered one of the most bizarre nights in advertising history. On June 13 of that year, the Clio president and officials were nowhere to be found as the ceremony started late. The event's caterer eventually became the impromptu host, and featured presenters who tried to guess the winners. Toward the end, a number of attendees rushed the stage and grabbed as many Clio statues they could. Simply put, it was chaos. The integrity of the Clios was said to be compromised, though we prefer to think of our low point as a colorful chapter from which we rebounded nicely.`
		}
	},
	{
		id: 10,
		x: 602,
		y: 1438,
		width: 276,
		height: 363,
		modal: {
			type: 'experience-photo',
			content: 'content/10-Leo-Burnett.jpg',
			heading: 'Leo Burnett',
			description: `When Leo Burnett founded his eponymous agency in 1935, he placed a bowl of red apples on the reception desk for visitors—a small gesture of generosity in very austere times. That kind of warmth, humanity and optimism was deeply embedded in the advertising he went on to make over the next four decades. A master of the soft sell, Burnett built campaigns around cultural archetypes, human emotions and compelling images. His creations included Tony the Tiger, the Jolly Green Giant, the Pillsbury Doughboy, the Marlboro Man and the oh-so-lonely Maytag Repairman. Burnett delivered a memorable and inspiring speech, titled "When to Take My Name Off the Door," to his agency in 1967 as he was retiring. He died four years later, but his name remains on the door—a testament to his lasting impact on the business. In the painting, Leo is seen by the bar with his hands gently placed together in a lowered steeple, suggesting his soft-sell technique of listening rather than speaking.`
		}
	},
	{
		id: 11,
		x: 746,
		y: 1381,
		width: 329,
		height: 218,
		modal: {
			type: 'experience-video',
			content: 'RJKn78NhK_g',
			heading: `Wendy's "Where's the Beef?"`,
			description: `Wendy's "Where's the Beef?," devised by Chicago agency Dancer Fitzgerald Sample, launched in 1984. Uttered in commercials by octogenarian actress Clara Peller, the three words became an iconic American catchphrase across all manner of media and merchandise (including a board game), and even featured in the 1984 U.S. Presidential election, when eventual Democratic nominee Walter Mondale asked the question of rival Gary Hart. Wendy's sacked Peller in 1985 after she appeared in a Prego spot exclaiming, "I found it!"`
		}
	},
	{
		id: 12,
		x: 739,
		y: 1543,
		width: 408,
		height: 617,
		zoom: 2,
		modal: {
			type: 'experience-video',
			content: 'ZaP_NrnKJkE',
			heading: `Tide "It's a Tide Ad"`,
			description: `"It's a Tide Ad" was created by Saatchi & Saatchi New York in 2018 and aired during Super Bowl LII. The irresistible, self-referential concept played with the notion that every Big Game ad that featured clean clothes (i.e., practically all of them) could be a Tide ad. To deliver the joke, Tide spoofed stereotypical brand categories and even famous commercials for Tide's P&G stable-mates Mr. Clean and Old Spice. Budweiser even provided footage for a Tide spot with the brewer's Clydesdale horses. The meta effort earned Tide the Super Clio award for best work on the game.`
		}
	},
	{
		id: 13,
		x: 933,
		y: 1446,
		width: 373,
		height: 358,
		modal: {
			type: 'experience-video',
			content: 'Tt5ZxJ-IqEU',
			heading: `Martian Smash`,
			description: `Smash Martians is one of the most beloved marketing campaigns in British history. Created by Boase Massimi Pollitt's John Webster, the TV spots featured alien robots that laughed at Earthlings for peeling potatoes instead of using Smash's instant mash. The bots were made of car parts and quickly became cult icons. "For Mash Get Smash" remains a familiar slogan in the U.K. today.`
		}
	},
	{
		id: 14,
		x: 1297,
		y: 1542,
		width: 143,
		height: 231,
		modal: {
			type: 'experience-video',
			content: '51GfhUdhyJU',
			heading: `IKEA "Lamp"`,
			description: `In 2002, Ikea lit its iconic "Lamp." In the spot, Crispin Porter + Bogusky and director Spike Jonze created an emotional buildup about a red desk lamp abandoned on the street. In the end, however, a Swedish dude appears to point out how absurd it is that we feel bad for this inanimate object. When one of the crew members took the lamp home, CP+B asked for it back and received a note back: "See? You've fallen for it. You've grown attached to the lamp, and you are crazy." The spot helped boost Ikea sales and won almost every industry award, including a Grand Clio.`
		}
	},
	{
		id: 15,
		x: 1274,
		y: 1345,
		width: 161,
		height: 242,
		modal: {
			type: 'experience-photo',
			content: 'content/David_Droga.jpg',
			heading: 'David Droga',
			description: `One of advertising's premier creative leaders over the past 25 years, David Droga launched his eponymous agency Droga5 in 2006 with a viral video for Marc Ecko showing graffiti artists appearing to tag the words "Still Free" on Air Force One. His agency has been on an incredible run ever since, with provocative, brilliantly crafted and breakthrough work for brands like The New York Times, Honey Maid, Under Armour, Jay-Z, Puma, Johnsonville, HBO, Prudential and Hennessy. Accenture acquired Droga5 this year, but under Droga's relentlessly iconoclastic vision, the agency will surely be "Still Free" for years to come. David Droga, the thinker, is seen sitting on top of the steps with his hand resting under his chin representing deep thought and contemplation.`
		}
	},
	{
		id: 16,
		x: 1203,
		y: 1195,
		width: 116,
		height: 318,
		modal: {
			type: 'experience-video',
			content: 'QNYsRdP8Tao',
			heading: `Apple "1984"`,
			description: `Apple's minute-long "1984," created by Chiat/Day and director Ridley Scott, is the most famous commercial ever made—the ad to change all ads, particularly Super Bowl spots. A dark and clever take on the dystopian George Orwell novel, it heralded Apple's arrival as a creative juggernaut under Steve Jobs and announced its beloved personal computer Macintosh to the world. It also established the Big Game as a platform for high-impact brand experiences. Clio named the spot to its Hall of Fame in 1995. Here, Chiat/Day creative guru Lee Clow hangs with the spot's badass Sledgehammer Girl—a symbol of defiance, perseverance and innovation.`
		}
	},
	{
		id: 17,
		x: 1121,
		y: 1187,
		width: 131,
		height: 330,
		modal: {
			type: 'experience-photo',
			content: 'content/clio-leeclow-1410x794.jpg',
			heading: 'Lee Clow',
			description: `Lee Clow, the laid-back surfer from Los Angeles who pioneered a West Coast version of Bernbach's Creative Revolution in the '60s and '70s, joined Chiat/Day in his twenties and would spend half a century there, creating some of the most iconic advertising of all time. Most famously he helped to create Apple's "1984" Super Bowl commercial and its "Think Different" campaign upon Steve Jobs' return to the company in 1997. Clow, who recently retired from TBWA/Media Arts Lab (the Apple-focused unit of TBWA), received Clio's Lifetime Achievement Award in 2004. In the painting, he stands next to the hammer-wielding heroine from "1984," a symbol of defiance, perseverance and innovation.`
		}
	},
	{
		id: 18,
		x: 921,
		y: 847,
		zoom: 2.5,
		width: 252,
		height: 546,
		modal: {
			type: 'experience-video',
			content: 'IPO2KCrjE60',
			heading: `Ronald McDonald`,
			description: `Ronald McDonald first appeared as the burger chain's mascot in 1963. He was known as the "Hamburger-Happy Clown," with a cup for a nose and a food tray on his head. Oh, and he magically pulled burgers from his belt, obviously. In 1966, Ronald got a makeover from Ringling Bros. and morphed into the icon we know today, ranking second only to Santa Claus as the most recognizable fictional character in America. Ronald is seen in the painting as a marble statue mirroring Burger King's King, symbolic of the ongoing feud between the two fast-food brands.`
		}
	},
	{
		id: 19,
		x: 840,
		y: 1199,
		width: 122,
		height: 161,
		modal: {
			type: 'experience-photo',
			content: 'content/colleen-decourcey.jpg',
			heading: `Colleen Decourcy`,
			description: `Colleen DeCourcy serves as co-president and global chief creative officer of Wieden + Kennedy, leading an eight-office network that consistently sets one of the industry's highest bars for creativity. In 2013, DeCourcy helped found a new group at W+K, The Lodge, that applies emerging technologies to solve brand problems. Earlier in her career, she ran media and design shop Socialistic. She claims "mutation" is her specialty, and credits her penchant for adaptation to her favorite book, Darwin's <i>On the Origin of Species</i>. In 2015, DeCourcy served as a juror for the Super Clio Awards.`
		}
	},
	{
		id: 20,
		x: 722,
		y: 1200,
		width: 141,
		height: 161,
		modal: {
			type: 'experience-photo',
			content: 'content/piyush-pandey.jpg',
			heading: `Piyush Pandey`,
			description: `Piyush Pandey, worldwide chief creative officer of Ogilvy & Mather and chairman of Ogilvy India, is known as the godfather of Indian advertising—or, as he prefers to put it, "Minister of Fun at O&M." He started his career as a tea taster at Goodricke Group. Today, he is most recognized for smart efforts on behalf of Cadbury Dairy Milk, Google Reunion and The Hindu. Pandey earned Clio's Lifetime Achievement Award in 2012. Piyush Pandey is in close proximity to the Cadbury Gorilla, a brand his agency has diligently worked on for years.`
		}
	},
	{
		id: 21,
		x: 450,
		y: 1223,
		width: 301,
		height: 128,
		modal: {
			type: 'experience-video',
			content: 'U_DLbmv-bbk',
			heading: `Cadbury Gorilla`,
			description: `The Cadbury Gorilla was uncaged by Fallon London in 2007. Director Juan Cabral, while on set for another shoot, came up with the idea during a conversation about great drum solos. Cadbury execs, anxious that the spot was too absurd and didn't show any product, kept it on the shelf for four months—but eventually aired it, to great acclaim, on the finale of <i>Big Brother</i> in the U.K. It was credited with boosting sales by 10 percent. The costume, which appeared in the 1995 film <i>Congo</i>, came from the Stan Winston Studio. In the painting, the gorilla is seen playing drums beside Piyush Pandey, the great Indian creative director who has worked with the brand for many years.`
		}
	},
	{
		id: 22,
		x: 1310,
		y: 1191,
		width: 146,
		height: 194,
		modal: {
			type: 'experience-photo',
			content: 'content/apple-ipod-silhouettes.jpg',
			heading: `Apple iPod "Silhouettes"`,
			description: `Apple's "Silhouettes" campaign for the iPod launched in 2003 from TBWA\Chiat\Day. The commercials featured black silhouettes dancing against bright backgrounds of solid colors. The anonymous silhouettes democratized the iPod, launching Apple to the forefront of the portable music market and advertising history. In 2002, the company held just five percent of the computer market. By 2004, sales of iPods and iTunes music contributed to 23 percent of Apple's earnings. The work succeeded through a simple, relatable message: Enjoy your music, no matter who or where you are.`
		}
	},
	{
		id: 23,
		x: 1450,
		y: 1193,
		width: 143,
		height: 186,
		modal: {
			type: 'experience-video',
			content: 'OdfDnQ5nJdE',
			heading: `Sony "Long Live Play"`,
			description: `Sony's "Long Live Play," launched in 2011 from Deutsch/LA, celebrated gaming and gamers in a special way. Touting the PlayStation 3, it featured 25 characters from the popular franchise. They were mostly flesh-and-blood actors whose features were enhanced by prosthetics, makeup and CGI. They all feted an everyday gamer named Michael. The campaign earned a Clio in 2012.`
		}
	},
	{
		id: 24,
		x: 1329,
		y: 1061,
		width: 124,
		height: 131,
		modal: {
			type: 'experience-photo',
			content: 'content/1200px-WR_Classic_head_shot.jpg',
			heading: 'Wallace Ross',
			description: `Wallace Ross founded the Clio Awards, originally known as the American Television Commercials Festival. He served as the festival's managing director from 1959 until 1971. During his time, Clio cemented itself in popular culture as the advertising industry's leading award show and barometer of creative excellence, and that status continues today. In the painting, Ross is seen standing in the back, overlooking the great ads that his award show has been witness to.`
		}
	},
	{
		id: 25,
		x: 1409,
		y: 1070,
		width: 255,
		height: 130,
		modal: {
			type: 'experience-photo',
			content: 'content/volkswagen.jpg',
			heading: 'Volkswagen "Think Small"',
			description: `Doyle Dane Bernbach's Volkswagen campaign in the late '50s and '60s was revolutionary, and its shining gem was "Think Small." From composition to copy, the work broke all the rules of traditional print advertising with its two-word headline and minimalist imagery—not to mention the unconventionally placed VW logo sitting inside the third paragraph. Its impact was huge, landing the campaign in the Clio Hall of Fame in 2013. You can see the Volkswagen Beetle parked on the third tier, next to the boy dressed as Darth Vader from the 2011 Super Bowl spot. A juxtaposition of old and new, this dynamic between the two Volkswagen ads represents a legacy of great advertising for the brand.`
		}
	},
	{
		id: 26,
		x: 1562,
		y: 1037,
		width: 169,
		height: 142,
		modal: {
			type: 'experience-video',
			content: '-Ua-wusm5fw',
			heading: `M&M's Characters`,
			description: `M&M’s had seen their popularity surge during World War II, but by 1995, sales had fallen flat. Challenged to revive the brand, they gave BBDO the business. Creative Directors Susan Credle and Steve Rutter went on to create the iconic colorful characters seen all over the world today. Susan Credle, now global chief creative officer at FCB, is regarded as one of most reputable and respected advertising creatives in the industry. She followed her M&M’s work with the launch of Cingular, All-State’s “Mayhem,” and award-winning work for brands like AT&T, Secret Deodorant, Pepsi, FedEx, Lowe’s, Bank of America and Visa. But even the acclaimed Credle said that the team could never have foreseen how big the M&M’s brand would become. The M&M’s characters’ first appearance was during the Super Bowl. While the colorful “comedic ensemble” continued to gain fame, Credle’s "aha moment” came when the Times Square store opened in 2006. Today, there are a total of five brick-and-mortar M&M’s World stores across the globe. Such efforts show how humanizing a brand can connect with consumers and revitalize a company.`
		}
	},
	{
		id: 27,
		x: 1690,
		y: 1115,
		width: 118,
		height: 163,
		modal: {
			type: 'experience-video',
			content: 'zp4TifmmaI8',
			heading: `Volkswagen "The Force"`,
			description: `Volkswagen's "The Force," created by Deutsch/LA in 2011, featured a kid dressed as Darth Vader who has no luck using his telekinetic powers until he "wills" a Passat to life (with an assist from his dad and the vehicle's remote start feature). Four days before kickoff, VW posted a 60-second cut of the spot on YouTube (it aired as a :30 during the game) and it quickly racked up millions of views, ushering in the now-common practice of advertisers pre-releasing Big Game ads. One of the most beloved Super Bowl spots of all time, it won a Gold Clio. Seen next to the original VW beetle from Burnbach's "Think Small", this represents a long history of iconic advertising from the VW brand.`
		}
	},
	{
		id: 28,
		x: 1616,
		y: 1268,
		width: 165,
		height: 219,
		modal: {
			type: 'experience-video',
			content: 'ES-o2uhxFIE',
			heading: `Graham`,
			description: `Graham, a model of a future human biologically evolved to survive automobile crashes, was created in 2016 by Clemenger BBDO Melbourne for Australia's Transport Accident Commission. Graham stands 6-foot-7, weighs 440 pounds and sports real human hair. The model was developed by artist Patricia Piccinini, along with trauma surgeon Christian Kenfield and road safety engineer Dr. David Logan. The "Meet Graham" campaign won numerous prizes, including a Gold Clio in 2017.`
		}
	},
	{
		id: 29,
		x: 3091,
		y: 925,
		width: 123,
		height: 345,
		modal: {
			type: 'experience-video',
			content: 'jAtyFZtfEOM',
			heading: `Halo "Believe"`,
			description: `Halo's "Believe" was a game-changer. In 2007, Microsoft, Bungie, T.A.G. McCann and AKQA launched a multifaceted campaign to promote Halo 3. By integrating various platforms—TV, an interactive website, a virtual museum and experiential activations—the work helped to solidify gaming as a worldwide cultural phenomenon. The campaign included a 1,200-square-foot digital diorama and over 1,000 models rendered from real-life actors. "Believe" championed emotional storytelling and blurred the lines between the Halo world and ours. The work earned a spot in Clio Hall of Fame in 2014. In the painting, Halo "Believe" is seen posing beside Reebok's Terry Tate.`
		}
	},
	{
		id: 30,
		x: 1501,
		y: 1372,
		width: 191,
		height: 491,
		modal: {
			type: 'experience-photo',
			content: 'content/Jones_Portrait_1975.jpg',
			heading: `Caroline Jones`,
			description: `Caroline Jones, who began her career in 1963 as a secretary and copywriter trainee at J. Walter Thompson, was one of the first female African American advertising executives to gain national prominence. She worked at a slew of agencies in her career, and helped found Caroline Jones Advertising, Zebra Associates and Mingo-Jones. She is perhaps best known for creating KFC's Reagan-era "We Do Chicken Right" campaign, and also worked on clients for American Express, Anheuser-Busch, McDonald's, Prudential and Toys ''R'' Us. She died in 2001 at age 59. In the painting, Caroline Jones can be seen standing beside David Ogilvy. We see a glove on her hand, symbolizing a rise in status within the industry.`
		}
	},
	{
		id: 31,
		x: 1638,
		y: 1481,
		width: 192,
		height: 367,
		modal: {
			type: 'experience-photo',
			content: 'content/DAvid-Ogilvy-lists.jpg',
			heading: 'David Ogilvy',
			description: `One of the towering figures of modern advertising, David Ogilvy founded Ogilvy & Mather in 1948. Having learned the business of persuasion by selling cooking stoves door-to-door in Scotland in his early days, Ogilvy transformed the industry by introducing data-driven tactics to underpin creative campaigns. A best-selling author on business topics who famously said, "The consumer isn't a moron. She's your wife," he created such famous ads as "The Man in the Hathaway Shirt" and "The Man From Schweppes Is Here." He died in 1999 at age 88. David Ogilvy was considered one of advertising's great thinkers. He can be seen in the painting sitting with his hand raised to his chin, deep in thought, contemplating the next big idea. On the table is his book titled <i>Confessions of an Advertising Man</i>.`
		}
	},
	{
		id: 32,
		x: 1702,
		y: 1763,
		width: 125,
		height: 191,
		modal: {
			type: 'experience-video',
			content: 'RPeGfUXJZL4',
			heading: `Taco Bell Dog`,
			description: `Gidget the Chihuahua debuted in 1997 as Taco Bell's mascot. The character was created by TBWA\Chiat\Day, and the catchphrase, "Yo quiero Taco Bell!" became a pop-culture staple until 2000, when the much-loved campaign ended. Gidget passed away in 2009 at age 15.`
		}
	},
	{
		id: 33,
		x: 1778,
		y: 1818,
		width: 246,
		height: 192,
		modal: {
			type: 'experience-video',
			content: 'tNUBWUMs3DA',
			heading: `Budweiser Puppy`,
			description: `Budweiser, whose famous advertising animals over the years have included frogs, lizards, Dalmatians and, of course, the Clydesdale horses, added a Yellow Lab to the mix for Super Bowl XLVIII in 2014. The "Puppy Love" spot, by Anomaly, was so beloved that Bud returned the following year with a sequel, "Lost Dog." Both spots ended up topping <i>USA Today's</i> Super Bowl Ad Meter as the most popular spot in the game.`
		}
	},
	{
		id: 34,
		x: 1934,
		y: 1376,
		width: 190,
		height: 519,
		modal: {
			type: 'experience-photo',
			content: 'content/BillBernbach.jpg',
			heading: 'Bill Bernbach',
			description: `Bill Bernbach, who in 1949 co-founded Doyle Dane Bernbach—later shortened to DDB—almost singlehandedly ushered in the Creative Revolution of the 1960s. He pioneered the practice of organizing art directors and copywriters into teams, and championed smart, clever, beautiful advertising driven by simplicity and an embrace of human emotion through storytelling. He is perhaps best known for his legendary Volkswagen work of the 1960s, including the iconic "Think Small" print ad. His work earned numerous Clios, landing Bernbach—who died in 1982—a special place in the show's history. Bernbach is standing central in the painting, reflecting his stature and prominence within advertising. He is wearing a pin that refers to his classic campaign for Avis Car Rental, themed "We try harder."`
		}
	},
	{
		id: 35,
		x: 1733,
		y: 1269,
		width: 194,
		height: 335,
		modal: {
			type: 'experience-video',
			content: 'pG0qZBUAY-o',
			heading: `Old Spice "The Man Your Man Can Smell Like"`,
			description: `Wieden + Kennedy developed "The Man Your Man Can Smell Like" in 2010 for P&G's Old Spice brand. Ads launched online during the weekend of Super Bowl XLIV, targeting women, who accounted for 60 percent of men's body-wash sales. The initial video generated so much buzz that W+K created a follow-up push featuring 186 personal responses to fans from campaign star Isaiah Mustafa. The work earned a Clio Award in 2010. You can see the Old Spice man at the center of the painting—he's on a horse, of course.`
		}
	},
	{
		id: 36,
		x: 1835,
		y: 1192,
		width: 146,
		height: 67,
		modal: {
			type: 'experience-video',
			content: '360AHZUvPqo',
			heading: `Budweiser Frogs`,
			description: `Budweiser's "Frogs" hopped onto screens in 1995 during Super Bowl XXIX. Created by DMB&B in St. Louis, the spots show three frogs rhythmically croaking "Bud," "Weis" and "Er." The campaign quickly became a pop-culture phenomenon. It generated spinoffs, parodies and multiple Big Game appearances, as well as a place in Clio's Hall of Fame.`
		}
	},
	{
		id: 37,
		x: 1764,
		y: 368,
		width: 347,
		zoom: 1.5,
		height: 888,
		modal: {
			type: 'experience-video',
			content: 'XKnLGGduTfE',
			heading: `Clios 1991 Incident`,
			description: `In 2016, Nick Offerman and Megan Mullally worked with Funny Or Die to create a re-enactment of the infamous 1991 Clio Awards ceremony, where snafus resulted in attendees rushing the stage and grabbing statues at random. This sketch's self-deprecating humor is a fun, lighthearted take on a dark moment in Clio history.`
		}
	},
	{
		id: 38,
		x: 1985,
		y: 1116,
		width: 103,
		height: 271,
		modal: {
			type: 'experience-photo',
			content: 'content/38-David-Lubars.jpg',
			heading: 'David Lubars',
			description: `David Lubars, creative chief of BBDO Worldwide and chairman of its North American operations, made everything from crowd-pleasing Super Bowl commercials (EDS "Cat Herders") to game-changing digital campaigns (BMW Films) during his time at Fallon. He moved to BBDO in 2004, where he has presided over an incredible 15-year creative run with clients like HBO, AT&T, FedEx, M&M's, GE, Macy's and many more. Campaigns guided by Lubars have won countless Clios, and the man himself earned the Lifetime Achievement Award in 2017. In the painting, he's seen eating a Snickers bar, a reference to BBDO's long-running "You're Not You When You're Hungry" campaign, while standing at the top of a staircase, indicating his trajectory of success.`
		}
	},
	{
		id: 39,
		x: 2074,
		y: 1184,
		width: 142,
		height: 344,
		modal: {
			type: 'experience-photo',
			content: 'content/alex-bogusky.jpg',
			heading: 'Alex Bogusky',
			description: `Alex Bogusky, chief creative engineer at CPB (formerly Crispin Porter + Bogusky), recently returned to the agency after an eight-year hiatus exploring social activism and other initiatives. In his first 20-year stint at the shop, he was the rock-star architect of innovative, buzz-building campaigns for the likes of Burger King, Volkswagen and the "Truth" anti-smoking campaign. He grew the agency from an unknown 16-person Miami operation to a multinational creative force with offices in Boulder, Los Angeles, London and Sweden. In 2010, Alex was named Creative Director of the Decade by <i>Adweek</i>. Now back at the shop he made famous, Bogusky leads creative direction, innovation, strategy and talent at CPB—and is working to recapture the magic, in a different form, as he tackles a business that changed significantly during his time away. In the painting, Alex's head is turned, directing the viewer's eye from the staircase to the Subservient Chicken (one of CPB's most iconic campaigns) standing across from him.`
		}
	},
	{
		id: 40,
		x: 2954,
		y: 1192,
		width: 135,
		height: 176,
		modal: {
			type: 'experience-photo',
			content: 'content/JEFF-GOODBY.jpg',
			heading: `Jeff Goodby`,
			description: `Rich Silverstein and Jeff Goodby have worked together for nearly four decades. Goodby began his professional life as a reporter and Silverstein served as an art director for various magazines. They met in 1980 at Ogilvy & Mather, where they were paired up by legendary adman Hal Riney, and won their first Clio Award for the Oakland A's "Billy Ball" campaign. They founded Goodby Silverstein & Partners in 1983, which has become one of adland's most praised and beloved agencies, generating iconic work for the California Milk Processor Board ("Got Milk?"), Budweiser (love those lizards!) and E*Trade (ook, ook chimpanzees). Some of their work resides in the permanent collection at the Museum of Modern Art in New York, and in 2010 they snagged a Clio Lifetime Achievement Award.`
		}
	},
	{
		id: 41,
		x: 2121,
		y: 1408,
		width: 221,
		height: 132,
		modal: {
			type: 'experience-video',
			content: 'me1PWXdMFfA',
			heading: `Sony "Balls"`,
			description: `In 2005, Fallon unleashed its epic "Balls" commercial for Sony's Bravia LCD TV. A quarter-million bouncing orbs launched from cannons and dumpsters cascaded down San Francisco's Russian Hill. A team of 50 interns equipped with roller skates and brooms gathered up the balls after the shoot. Sadly, they could not prevent broken windows, dented cars and a bruised PA. The spot blurred the line between art and advertising, earning a Gold Clio and a Hall of Fame Award.`
		}
	},
	{
		id: 42,
		x: 2200,
		y: 1182,
		width: 165,
		height: 213,
		modal: {
			type: 'experience-video',
			content: 'ZUSteLzciq4',
			heading: `Starburst "Bus Station"`,
			description: `Starburst's "Bus Station" commercial was created by TBWA\Chiat\Day New York in 2007. It introduced viewers to the Little Lad, an eccentric fellow who's so ecstatic about Starburst's new "Berries & Creme" flavor that he breaks out in song and dance. A prime example of oddvertising (which the same agency also pioneered for Skittles), the spot—directed by Randy Krallman—earned cult status and a place in Clio history.`
		}
	},
	{
		id: 43,
		x: 2339,
		y: 1181,
		width: 163,
		height: 214,
		modal: {
			type: 'experience-video',
			content: 'dmq0yL64BC4',
			heading: `Skittles "Bleachers"`,
			description: `Created by DDB Chicago, Skittles' "Bleachers" spot, where a kid suffers an outbreak of "Skittlespox," aired in 2012. It's one of scads of surreal Skittles spots that have run through the years—including early ones like "Sheepboys," "Touch" and "Piñata Man" from TBWA\Chiat\Day New York. Sometimes dubbed "oddvertising," Skittles has extended the tradition into offbeat Super Bowl stunts from DDB in the past few years, including a Big Game spot that only one kid saw and a Broadway musical that ran for a single performance on Super Bowl Sunday. `
		}
	},
	{
		id: 44,
		x: 2174,
		y: 1061,
		width: 183,
		height: 139,
		modal: {
			type: 'experience-video',
			content: 'a30Gmkb26DQ',
			heading: 'Coca-Cola "Hilltop"',
			description: `Coca-Cola's "Hilltop" spot was created by McCann-Erickson in 1971. The idea came to McCann exec Bill Backer while he was stranded at an airport cafe in Ireland. After seeing other grounded travelers turn their frustrations into smiles while sharing stories and sipping the beverage, Backer came up with the tagline "I'd like to buy the world a Coke." The jingle was written in 24 hours, and the spot was filmed on a hilltop in Italy. The ad not only won a Clio in 1972, but the song was also rerecorded, licensed as a pop track, and played on the radio. A placid, melodic appeal to the goodness in humanity, it arrived during the height of the Vietnam War and has long been revered as an example of advertising's power to set the tone in culture, not just mirror it.`
		}
	},
	{
		id: 45,
		x: 2288,
		y: 1001,
		width: 203,
		height: 180,
		modal: {
			type: 'experience-video',
			content: '3ETv55mlkmU',
			heading: `Kool-aid Man`,
			description: `Kool-Aid Man burst onto the scene in 1954 as "The Pitcher Man," but by the 1970s, thanks to campaigns from Grey, he morphed into the stirring ad character we all know and love. His trademark wall-busting stemmed from an offhand suggestion from a producer on set. Kool-Aid Man became so popular, he was featured in video games, comic books and a museum display. Oh yeah!`
		}
	},
	{
		id: 46,
		x: 2389,
		y: 1188,
		width: 179,
		height: 348,
		modal: {
			type: 'experience-photo',
			content: 'content/george-w-sane-poster_custom-b5f7afd0aba38aac1887886858ad24e6665c38eb.jpg',
			heading: 'George Lois',
			description: `One of the original Mad Men, George Lois was a pioneer of the Creative Revolution in American advertising during his early years, first at DDB and later at Papert Koenig Lois and Lois, Holland, Callaway. He championed "the Big Idea" to guide campaigns for Xerox, MTV (including the famous slogan "I Want My MTV"), VH1, Tommy Hilfiger and ESPN. Lois, who turned 88 in June, received a Clio Lifetime Achievement award in 2013. He can be seen in the painting with an arrow in his back—a reference to the iconic Muhammad Ali cover he designed for Esquire.`
		}
	},
	{
		id: 47,
		x: 2529,
		y: 1182,
		width: 139,
		height: 276,
		modal: {
			type: 'experience-video',
			content: '95ZYZxn4TLc',
			heading: `Levi's "Launderette"`,
			description: `Levi's "Launderette" commercial, in which a buff dude goes into a London laundry and strips down to his boxer shorts as he washes his clothes, was created by Bartle Bogle Hegarty in 1985. The mildly racy spot increased sales of Levi's 501 jeans at the time by a stunning 800 percent … and helped boxers boom, too.The campaign proved a milestone in Levi's advertising legacy. In the painting, the Launderette character is seen sitting casually on top of a laundry machine, reading a magazine as depicted in the famous commercial.`
		}
	},
	{
		id: 48,
		x: 2639,
		y: 1250,
		width: 135,
		height: 329,
		modal: {
			type: 'experience-photo',
			content: 'content/clio-specialtyawards-lindakaplin-1410x794.jpg',
			heading: 'Linda Kaplan Thaler',
			description: `Linda Kaplan Thaler, the longtime chief of The Kaplan Thaler Group (which merged with Publicis in 2012), is responsible for shepherding work that helped inform popular culture. Her efforts gave us such classics as the Aflac duck, the Toys "R" Us jingle "I Don't Wanna Grow Up," "Kodak Moments" and Herbal Essences' orgasmic "Organic Experience." In 2016, Thaler earned Clio's Lifetime Achievement Award.`
		}
	},
	{
		id: 49,
		x: 2735,
		y: 1209,
		width: 111,
		height: 145,
		modal: {
			type: 'experience-photo',
			content: 'content/Tom_Burrell.jpg',
			heading: `Tom Burrell`,
			description: `By giving African American marketing its own distinct voice, Tom Burrell, founder of Burrell Communications in 1971, forever changed the face of advertising. Burrell famously said, "Black people are not dark-skinned white people," as he pushed for more authentic connections. Burrell's 1976 Coca-Cola commercial "Street Song," which authentically captured the sprit and drive of an inner-city neighborhood, won the agency its first Clio Award.`
		}
	},
	{
		id: 50,
		x: 2804,
		y: 1223,
		width: 114,
		height: 129,
		modal: {
			type: 'experience-video',
			content: 'sVxMWQ9Z45k',
			heading: 'Tango Orange Man',
			description: `London agency HHCL created "You Know When You've Been Tango'd" in the early '90s. Soon after its release, the commercial, featuring the iconic Orange Man slapping Tango drinkers, was removed after reports that children were mimicking the commercial and "Tango-ing" one another. (Ouch!) Ultimately, the spot was reworked to show the Orange Man comically kissing Tango drinkers instead. In the painting, the Tango man is sneaking up on his next victim.`
		}
	},
	{
		id: 51,
		x: 2891,
		y: 1210,
		width: 132,
		height: 135,
		modal: {
			type: 'experience-video',
			content: 'I8RwA81GQCk',
			heading: `Colonel Sanders`,
			description: `Most of us know Colonel Harland Sanders as the brand-centric ambassador of fried chicken and Southern hospitality. But his career simmered for years before reaching a full boil. After being fired from dozens of jobs, Sanders painted his face on his car and drove around America asking restaurant owners to adopt his fried-chicken recipe, finally achieving success in 1952 at age 60. He lived a robust life until his death in 1980. Wieden + Kennedy Portland revived the character in 2015, and so far, 12 different actors have played the role.`
		}
	},
	{
		id: 52,
		x: 2639,
		y: 856,
		width: 271,
		height: 483,
		modal: {
			type: 'experience-video',
			content: 'OrTETTGNhlQ',
			heading: `The Burger King`,
			description: `The Burger King became the official mascot for the fast-food chain in 1955, appearing on signage at the chain's first restaurant in Miami. His Highness changed a lot through the years. At one point, he was known as "Kurger Bing." In 2003, Crispin Porter + Bogusky rebranded the character using a giant king's head. Later, a masked version of the royal one hid in people's beds and offered them Croissan'wiches for breakfast. The Burger King represents the chain's commitment to a bold and innovative advertising strategy that still reigns.`
		}
	},
	{
		id: 53,
		x: 3244,
		y: 1208,
		width: 77,
		height: 96,
		modal: {
			type: 'experience-video',
			content: 'SxqKZmEb23A',
			heading: `Love Has No Labels`,
			description: `"Love Has No Labels" launched on Valentine's Day 2015 as an integrated push by R/GA New York for the Ad Council. Designed to confront biases of all sorts, the campaign featured an outsized X-ray installation in Santa Monica. Behind the screen, people of various ages, creeds and ethnicities danced and hugged, often surprising the crowd when they emerged. (No one expected two women kissing, apparently.) An Emmy winner, it's considered one of the most successful PSA campaigns in history, even spinning off into a docu-series in 2019. A "Love Has No Labels" placard is placed upon the column between two men, a reminder of compassion and tolerance.`
		}
	},
	{
		id: 54,
		x: 3413,
		y: 845,
		width: 168,
		height: 411,
		modal: {
			type: 'experience-video',
			content: 'rExyIsquxX0',
			heading: `Reebok Terry Tate`,
			description: `Terry Tate, played by aspiring football player Lester Speight, was a character who appeared in a series of TV commercials for Reebok beginning with Super Bowl XXXVII in 2003—in which he tackled office drones who had poor workplace etiquette. Despite airing just once on national TV, the video was viewed more than 7 million times on Reebok's website, making it one of the first viral video campaigns in the days before YouTube. Its success resulted in a series of long-form spots. In the painting, "Terrible" Terry Tate poses next to Halo's "Believe."`
		}
	},
	{
		id: 55,
		x: 3665,
		y: 1673,
		width: 136,
		height: 133,
		modal: {
			type: 'experience-video',
			content: '0Ldcnq4Y9fE',
			heading: `Southern Comfort "Whatever's Comfortable"`,
			description: `"Whatever's Comfortable" promoted Southern Comfort with unabashed pride. In 2012, Wieden + Kennedy New York debuted the work with a "Beach" spot starring a dad-bod dude who struts across the sand wearing nothing but a tight swimsuit and shoes, his confidence radiating with each step. Sadly, the ad couldn't air in some markets, such as Australia, where laws forbid showing alcoholic beverages on beaches. "Whatever's Comfortable" helped boost brand sales and won a pair of Gold Clios.`
		}
	},
	{
		id: 56,
		x: 3390,
		y: 1499,
		zoom: 2.25,
		width: 310,
		height: 661,
		modal: {
			type: 'experience-photo',
			content: 'content/marywellslawrence-860x741.jpg',
			heading: 'Mary Wells Lawrence',
			description: `What glass ceiling? Mary Wells Lawrence, founding president of Wells, Rich, Greene, was the first female CEO of a company listed on the New York Stock Exchange. While at Jack Tinker and Partners early in her career, she made a name for herself with stylish campaigns for Braniff International Airways. She is also known for her campaigns "I Can't Believe I Ate the Whole Thing" for Alka-Seltzer (a Clio winner in 1971), "Trust the Midas Touch" and the Empire State tourism staple "I Love New York." She turned 91 this spring. In the painting, Mary can be seen at the bar, standing tall even next to The Most Interesting Man in the World.`
		}
	},
	{
		id: 57,
		x: 3047,
		y: 1560,
		width: 434,
		height: 600,
		zoom: 2.5,
		modal: {
			type: 'experience-video',
			content: 'WGcHnD5qpUI',
			heading: `Dos Equis "Most Interesting Man in the World"`,
			description: `Dos Equis' "Most Interesting Man in the World" was created by Euro RSCG (now Havas) in New York in 2006. At a time when craft beer was ascendant in U.S. markets, Dos Equis became a strong contender among imported brews. This worldly gent charmed viewers with clever, amusing copy and a tone of cool absurdity. The campaign helped the brand triple sales from 2006 to 2010 and won numerous Clio Awards—a good reminder to "Stay thirsty, my friends!" In the painting, the Most Interesting Man in the World is seen by the bar, of course—socializing and detailing stories of his adventurous past.`
		}
	},
	{
		id: 58,
		x: 3197,
		y: 1397,
		width: 294,
		height: 370,
		modal: {
			type: 'experience-video',
			content: 'MCeuQaY5bc4',
			heading: 'Burger King "Subservient Chicken"',
			description: `Crispin Porter + Bogusky and The Barbarian Group created Burger King's "Subservient Chicken" in 2004. The groundbreaking digital campaign promoted BK's TenderCrisp Chicken Sandwich with a microsite that let users order a funky chicken to obey their commands. The online site attracted more than 750,000 users and 17 million hits as it revolutionized the way advertisers approached interactive media. After reversing BK's sales declines and winning a 2005 Grand Clio, the barmy bird made a comeback 10 years later.`
		}
	},
	{
		id: 59,
		x: 3055,
		y: 1408,
		width: 240,
		height: 236,
		modal: {
			type: 'experience-video',
			content: '_N6BC25hcfY',
			heading: `Budweiser "Whassup"`,
			description: `Budweiser's "Whassup" commercials first aired in 1999. The initial classic spot, created by DDB Chicago, shows a group of male friends who speak over the phone—uttering the gutteral catchphrase—while watching a game. Though some 80 actors auditioned, DDB hired real-life pals to play themselves. "Whassup" quickly became a common greeting and iconic catchphrase, winning the 2000 Grand Clio and entering the show's Hall of Fame in 2006. In the painting we see one of the friends relaxing on a couch that's somewhat more grandiose than the one from the Budweiser commercial.`
		}
	},
	{
		id: 60,
		x: 3049,
		y: 1658,
		width: 104,
		height: 134,
		modal: {
			type: 'experience-video',
			content: 'ZOI64LrV9bg',
			heading: `Folgers coffee`,
			description: `Since the early 1990s, Folgers has been the best-selling ground coffee in the U.S. Its famous jingle, "The best part of waking up is Folgers in your cup," has been featured in almost every spot since 1984. It's been remixed into country, gospel, jazz, R&B, folk, Celtic and a cappella versions. Folgers has innovated with each coffee trend, introducing reusable glass bottles during World War II and, more recently, coffee pods. Folgers ads have earned the brand many Clio Awards and a place in the show's history.`
		}
	},
	{
		id: 61,
		x: 2984,
		y: 1662,
		width: 70,
		height: 134,
		modal: {
			type: 'experience-video',
			content: 'NOJYJ6mmZZc',
			heading: `Guinness`,
			description: `Guinness was founded in 1759. By the early 20th century, the Irish brewer set the bar for beer advertising with its famous tagline, "Guinness is good for you." At the time, Irish hospitals even gave mothers a glass of stout after giving birth due to its high iron content. In the 1970s, the brand saluted women to counter the stout's "manly" stereotype by encouraging women to ignore the "stares, winks and nudges and just enjoy your beer." Throughout the years, Guinness has constantly reinvented itself through great campaigns. When sales dipped due to impatient drinkers who didn't want to wait for a slow-poured Guinness at the pub, the brand reminded people—most memorably in the "Surfer" advert&ndash;that "Good things come to those who wait." Other iconic campaigns, including "Noitulove" and "Made of More," prove the cream really does rise to the top.`
		}
	},
	{
		id: 62,
		x: 2865,
		y: 1431,
		width: 230,
		height: 339,
		modal: {
			type: 'experience-photo',
			content: 'content/62-Susan-hoffman.jpg',
			heading: 'Susan Hoffman',
			description: `Susan Hoffman, chairman of Wieden + Kennedy, joined the agency as its eighth employee in 1985 and has created much of its iconic work, most notably on Nike but also on brands like Old Spice, Levi's, Chrysler, Target and Procter & Gamble. Dan Wieden once said Hoffman "keeps us a little crazy, which is in the DNA of this place." She has won numerous Clio honors, including the Lifetime Achievement Award in 2018. In the painting, Susan is standing in the foreground, near Dan Wieden and David Kennedy but separate from them, as her legacy stands on its own.`
		}
	},
	{
		id: 63,
		x: 2832,
		y: 1594,
		width: 98,
		height: 197,
		modal: {
			type: 'experience-video',
			content: 'rowqBQmhoUo',
			heading: `Geico Gecko`,
			description: `In 1999, insurance giant Geico began receiving letters from confused consumers who mistook the brand name as "Gecko." To address this, The Martin Agency created a single spot using a gecko. That would have been the end for our green little friend, if not for an actor's strike that year, leaving a dearth of talent. Geico asked Martin for more gecko spots as a stopgap. The rest is adorable, animated, Cockney-accented history.`
		}
	},
	{
		id: 64,
		x: 2776,
		y: 1608,
		width: 89,
		height: 195,
		modal: {
			type: 'experience-photo',
			content: 'content/absolut.jpg',
			heading: `Absolut`,
			description: `In 1980, TBWA created an ad for Absolut vodka that featured the bottle with a halo and the line, "Absolut Perfection." It was first of some 2,000 executions to date, which ranks as the longest-running print campaign of all time. With a sophisticated sense of style and cultural relevance, the ads proved so popular that teenagers and young adults used them as posters. The iconic effort has won hundreds of awards, with many Clios among them.`
		}
	},
	{
		id: 65,
		x: 2756,
		y: 1441,
		width: 165,
		height: 151,
		modal: {
			type: 'experience-video',
			content: 'NJwyJXUNq2A',
			heading: `Alka-Seltzer "Spicy Meatball"`,
			description: `In 1969, Doyle Dane Bernbach created the "Spicy Meatball" spot for Alka-Seltzer. The commercial is a send-up of the making of a commercial, with a middle-aged actor eating meatball after meatball during a shoot while flubbing his line, "Mamma mia, that's a spicy meatball!" which became one of the most memorable slogans in advertising history. The actor bit into 175 meatballs for the actual spot, and reportedly refused the team's offer of lunch and dinner. The spot's meta approach was ahead of its time, and rightly earned a place in Clio history.`
		}
	},
	{
		id: 66,
		x: 2147,
		y: 1506,
		width: 69,
		height: 110,
		modal: {
			type: 'experience-video',
			content: 'CTQwdA6R5_w',
			heading: 'Got Milk?',
			description: `"Got Milk?"—one of the most iconic slogans ever written—was created by Goodby Silverstein & Partners in 1993 for the California Milk Processor Board and universally lauded for its entertaining ads that cleverly centered on the absence of the product. To test the campaign, GS&P played a trick on its staff. After secretly emptying every carton, the shop filmed employees fumbling around the fridge looking for milk for their coffee—concluding that taste and cravings rather than health claims should be the key to the campaign.`
		}
	},
	{
		id: 67,
		x: 2441,
		y: 1402,
		width: 224,
		height: 370,
		modal: {
			type: 'experience-photo',
			content: 'content/DavidKennedy.jpg',
			heading: 'David Kennedy',
			description: `Dan Wieden and David Kennedy, founders of independent agency Wieden + Kennedy, are best known for creating Nike's groundbreaking ""Just Do It"" campaign—though their creative output over the past 37 years has been second to none across all sorts of brands and product categories. Their motto, ""Fail Harder,"" is spelled out entirely in push pins on a mural at W+K's Portland, Ore., headquarters, and captures their fearless approach to advertising. The duo landed the Clio Lifetime Achievement Award in 2009. You can see them up front at the bar—Wieden is holding a ""Just Do It"" mug and wearing a shirt bearing the agency's motto.`
		}
	},
	{
		id: 68,
		x: 2280,
		y: 1393,
		width: 353,
		height: 351,
		modal: {
			type: 'experience-photo',
			content: 'content/DanWieden.jpg',
			heading: 'Dan Wieden',
			description: `Dan Wieden and David Kennedy, founders of independent agency Wieden + Kennedy, are best known for creating Nike's groundbreaking "Just Do It" campaign—though their creative output over the past 37 years has been second to none across all sorts of brands and product categories. Their motto, "Fail Harder," is spelled out entirely in push pins on a mural at W+K's Portland, Ore., headquarters, and captures their fearless approach to advertising. The duo landed the Clio Lifetime Achievement Award in 2009. You can see them up front at the bar—Wieden is holding a "Just Do It" mug and wearing a shirt bearing the agency's motto.`
		}
	},
	{
		id: 69,
		x: 2599,
		y: 1739,
		width: 208,
		height: 77,
		modal: {
			type: 'experience-video',
			content: '2zswUDvORjY',
			heading: `Dove "Real Beauty"`,
			description: `In 2004, Dove agency Edelman discovered that only two percent of women considered themselves beautiful. Using this insight, the brand saw an opportunity to start a conversation about beauty and drive social change. The award-winning "Real Beauty" campaign celebrated confidence and the natural physical differences among women. Not only did Dove's sales increase, but the long-running effort transformed the way beauty companies advertise to women.`
		}
	},
	{
		id: 70,
		x: 3068,
		y: 217,
		width: 103,
		height: 299,
		modal: {
			type: 'experience-video',
			content: 'kvnIaF3UaUs',
			heading: `mr. peanut`,
			description: `Mr. Peanut's "real" name is Bartholomew Richard Fitzgerald-Smythe. Reportedly a Brit, he is never seen without a suit, top hat, gloves, cane and monocle. The iconic Planters brand mascot was born in 1916, when a 14-year-old won a sketching contest sponsored by the brand. He made his Super Bowl debut in 2018, driving his Nutmobile.`
		}
	},
	{
		id: 71,
		x: 3264,
		y: 33,
		width: 169,
		height: 234,
		modal: {
			type: 'experience-video',
			content: 'VMy0SrrsFX4',
			heading: `Geico Cavemen`,
			description: `The Martin Agency unleashed Geico's Cavemen in 2004. Much to their chagrin, the Neanderthals were used to demonstrate that saving money with Geico insurance is "So easy, a caveman can do it." Their hirsute hijinks led to a 2007 sitcom, and the characters joined the Madison Avenue Ads Walk of Fame in 2008. Each caveman's makeup took about three hours to complete. The commercials were a novel alternative to traditional insurance ads and a key part of Geico's climbing of advertising's evolutionary ladder.`
		}
	},
	{
		id: 72,
		x: 2071,
		y: 275,
		width: 235,
		height: 228,
		modal: {
			type: 'experience-video',
			content: '_QhI21nkstM',
			heading: `Red Bull Stratos`,
			description: `In 2012, Red Bull really spread its wings with "Red Bull Stratos" or "The Mission to the Edge of Space." The initiative sent Austrian skydiver Felix Baumgartner 24 miles into the stratosphere over New Mexico in a helium balloon. Once he reached that height, Baumgartner began his free fall in a pressurized suit and ultimately parachuted back to Earth. His top speed was 844 miles per hour, and he actually broke the sound barrier. Despite the dangers—one of his hands swelled to twice its normal size and there was an unexpected mid-air tumble—he landed safely. The event was streamed on 80 TV stations in 50 countries. It became the most-watched livestream in history, racking up 52 million global views.`
		}
	},
	{
		id: 73,
		x: 1226,
		y: 178,
		width: 376,
		height: 235,
		modal: {
			type: 'experience-video',
			content: 'uQH0toh53BU',
			heading: 'Guinness "Surfer"',
			description: `Guinness "Surfer," made by Abbott Mead Vickers BBDO in 1999, is one of the all-time classic commercials. Riffing on Walter Crane's 1893 painting Neptune's Horses, it likened a slow-poured pint of Guinness to waiting … and waiting … and waiting for the perfect wave. Directed by Jonathan Glazer, with poetic narration and spectacular special effects, the spot thrilled audiences everywhere—and won numerous Clios.`
		}
	},
	{
		id: 74,
		x: 2397,
		y: 1571,
		width: 60,
		height: 67,
		modal: {
			type: 'experience-video',
			content: '7mr7ChI9s8w',
			heading: `Nike "Just Do It"`,
			description: `Nike's "Just Do It" tagline is regarded as perhaps the most iconic in advertising history. Created by Wieden + Kennedy Portland in 1988, it has been running for over 30 years with no signs of slowing down. The mantra has inspired generations of athletes around the world and has become synonymous with winning. It has also become a voice for underdogs tackling issues on and off the field. From "If You Let Me Play Sports" to "Find Your Greatness" to the "Dream Crazy" campaign with Colin Kapernick, the line has unapologetically signaled a commitment to social issues including race, gender equality, ageism, disabilities, LGBTQ+ rights and more. Not bad for a slogan originally inspired by Gary Gilmore's last words. "Just Do It" has earned its place in Clio history, with Nike campaigns winning numerous Clios through the years. Here, we see Dan Wieden, who coined the phrase, holding a mug displaying the iconic slogan.`
		}
	},
	{
		id: 75,
		x: 2093,
		y: 1554,
		width: 88,
		height: 56,
		modal: {
			type: 'experience-video',
			content: 'SkSnHnmMcaE',
			heading: `Kellogg Eggo`,
			description: `Kellogg's Eggo waffles debuted in 1953, when frozen foods and TV dinners were staples for postwar American families. Its catchphrase, "Leggo My Eggo," created by Leo Burnett, is still one of the most iconic advertising slogans. Recently, Eggo's popularity soared due to its appearances in the Netflix series <i>Stranger Things</i>. The brand released recipes, toasters and other tie-ins, and even appeared in a <i>Stranger Things</i> spot during Super Bowl LI that won a Clio Award.`
		}
	},
	{
		id: 76,
		x: 2476,
		y: 1634,
		width: 61,
		height: 157,
		modal: {
			type: 'experience-video',
			content: '_rWgHy8mSeM',
			heading: `Coca-Cola`,
			description: `Coca-Cola is one of the most recognizable brands on the planet. It's become famous for holiday campaigns, releasing collectible yuletide bottles and putting polar bears and Santa Claus in its seasonal ads. Coke's sponsorship of the Olympic Games stretches back to 1928, and its global impact was bolstered by the famous "I'd Like to Buy the World a Coke" campaign in 1971. That spot, aired during the Vietnam era, showed different nationalities promoting world peace. The brand stayed relevant by adapting to technology and social media, with 2014's "Share a Coke" ranking among its most successful ad efforts ever. In 2013, Coke won Clio's inaugural Brand Icon Award.`
		}
	},
	{
		id: 77,
		x: 2418,
		y: 1748,
		width: 122,
		height: 51,
		modal: {
			type: 'experience-video',
			content: 'I9uCc2ZEu64',
			heading: `Delta "Runways"`,
			description: `"Runways" was more mini-film than commercial. Created by Wieden + Kennedy in 2018, the stylish video displayed more than 15 Delta Airlines destinations. Dinner tables, a hotel facade, a wooden bridge and more became runways, with the point of view constantly moving forward, as if viewers were aboard a plane about to take off. The visuals combined 2-D and 3-D elements, while cameras shot upside down and sideways. Narrated by Oscar winner Viola Davis, the cinematic piece landed a spot in Clio history.`
		}
	},
	{
		id: 78,
		x: 2301,
		y: 1712,
		width: 128,
		height: 82,
		modal: {
			type: 'experience-video',
			content: 'eGCLHpzC480',
			heading: `P&G "Thank You, Mom"`,
			description: `Procter & Gamble's "Thank You, Mom" campaign was created by Wieden + Kennedy Portland and broke during the 2010 Winter Olympic Games in Vancouver. "Thank You, Mom" not only won hearts, but ranks among P&G's most successful campaigns, earning 76 billion global media impressions and 74 million global views—along with several Clio Awards. In the painting, "Thank You, Mom" is represented by a letter, because a handwritten note carries special significance in the digital age.`
		}
	},
	{
		id: 79,
		x: 3042,
		y: 1216,
		width: 87,
		height: 133,
		modal: {
			type: 'experience-photo',
			content: 'content/clio-richsilverstein.jpg',
			heading: `Rich Silverstein`,
			description: `Rich Silverstein and Jeff Goodby have worked together for nearly four decades. Goodby began his professional life as a reporter and Silverstein served as an art director for various magazines. They met in 1980 at Ogilvy & Mather, where they were paired up by legendary adman Hal Riney, and won their first Clio Award for the Oakland A's "Billy Ball" campaign. They founded Goodby Silverstein & Partners in 1983, which has become one of adland's most praised and beloved agencies, generating iconic work for the California Milk Processor Board ("Got Milk?"), Budweiser (love those lizards!) and E*Trade (ook, ook chimpanzees). Some of their work resides in the permanent collection at the Museum of Modern Art in New York, and in 2010 they snagged a Clio Lifetime Achievement Award.`
		}
	},
	{
		id: 80,
		x: 914,
		y: 1230,
		width: 102,
		height: 130,
		modal: {
			type: 'experience-video',
			content: 'DtuqjeiFes8',
			heading: `Spuds MacKenzie`,
			description: `Party pooch Spuds MacKenzie made his first appearance for Bud Light during the Super Bowl in 1987. The instant popularity of this bull terrier increased brand sales by 20 percent through 1988. Spuds was, in fact, played by a female dog named Honey Tree Evil Eye (or "Evie" for short). Though the campaign was short lived, Spuds became one of the most famous brand mascots of all time. In the painting, Spuds sits atop the marble counter, seeming more like a person than a pet.`
		}
	},
	{
		id: 101,
		x: 2065,
		y: 1048,
		width: 192,
		height: 140,
		modal: {
			type: 'experience-video',
			content: '-Ua-wusm5fw',
			heading: `M&M's Characters`,
			description: `M&M’s had seen their popularity surge during World War II, but by 1995, sales had fallen flat. Challenged to revive the brand, they gave BBDO the business. Creative Directors Susan Credle and Steve Rutter went on to create the iconic colorful characters seen all over the world today. Susan Credle, now global chief creative officer at FCB, is regarded as one of most reputable and respected advertising creatives in the industry. She followed her M&M’s work with the launch of Cingular, All-State’s “Mayhem,” and award-winning work for brands like AT&T, Secret Deodorant, Pepsi, FedEx, Lowe’s, Bank of America and Visa. But even the acclaimed Credle said that the team could never have foreseen how big the M&M’s brand would become. The M&M’s characters’ first appearance was during the Super Bowl. While the colorful “comedic ensemble” continued to gain fame, Credle’s "aha moment” came when the Times Square store opened in 2006. Today, there are a total of five brick-and-mortar M&M’s World stores across the globe. Such efforts show how humanizing a brand can connect with consumers and revitalize a company.`
		}
	},
]