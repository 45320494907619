import React, { useGlobal, useState } from 'reactn'
import MobileMenu from './MobileMenu'
import ShareMenu from './ShareMenu'
import HeaderLogo from '../assets/header-logo.png'
import IconSeeMore from '../assets/icon-play.png'
import IconTicket from '../assets/icon-ticket.png'
import IconSocial from '../assets/icon-social.png'
import animateScrollTo from 'animated-scroll-to'
import './Header.scss'

function HeaderItem (props) {
  return (
    <div className="header-item" onClick={props.function}>
      <img className="header-image" alt="" src={props.src}/>
      <div className="header-label">{props.label}</div>
    </div>
  )
}

function HeaderLink (props) {
  return (
    <div className="header-item">
      <img className="header-image" alt="" src={props.src}/>
      <a href={props.link} className="header-label" target="_blank">{props.label}</a>
    </div>
  )
}

function Header () {
  const [ isMobile ] = useGlobal('isMobile')
  const [shareStatus, setShareStatus] = useGlobal('shareStatus')
  

  function share() {
    setShareStatus(!shareStatus)
  }

  function scroll() {
    animateScrollTo(document.querySelector('.content'))
  }

  return (
    <div className="header">
      <img className="logo" alt="" src={HeaderLogo} />
      { !isMobile && <div className="items">
        <HeaderItem src={IconSeeMore} label="See More" function={scroll} />
        <HeaderLink src={IconTicket} label="Buy Tickets" link=" http://bit.ly/2YNDv8I"/>
        <HeaderItem src={IconSocial} label="Share" function={share}/>
        <ShareMenu open={shareStatus} defaultValue={false} />
      </div> }
      { isMobile &&
        <MobileMenu/>
      }
    </div>
  )
}

export default Header
