import React, {  useState, useGlobal } from 'reactn'
import Modal from './Modal'
import DeepZoom from './DeepZoom'
import NavGuide from '../assets/nav-guide.png'
import NavGuideVideo from '../assets/zoom.mp4'
import NavGuideVideoWebm from "../assets/zoom.webm"
import './Experience.scss'

function Experience () {
  const [ overlayHidden, setOverlayHidden ] = useState(false)
  const [ experienceModal, setExperienceModal ] = useGlobal('experienceModal')
  const [ experienceModalSelectedObject, setExperienceModalSelectedObject ] = useGlobal('experienceModalSelectedObject')

  function clickButton () {
    setOverlayHidden(true)
  }

  function close () {
    setExperienceModal(null)
    setExperienceModalSelectedObject(null)
  }

  return (
    <div className="experience" {...{ [overlayHidden ? 'data-hidden' : 'data-visible']: '' }}>
      <DeepZoom {
        ...{
        Image: {
          xmlns: 'http://schemas.microsoft.com/deepzoom/2008',
          Url: 'painting_files/',
          Format: 'jpg',
          Overlap: '1',
          TileSize: '256',
          Size: {
            Height: '2048',
            Width: '2048',
          }
        }
      }} />
      <div className="overlay">
        <div className="overlay-contents">
          <h2>60 Years</h2>
          <h3>of the Clios</h3>
          <p>For six decades, the Clio Awards has been a witness to some of the best creatives and campaigns in the industry.</p>
          <p className="nav-instructions">Click and drag to see 60 years of advertising’s most iconic moments.</p>
          <video playsInline autoPlay muted loop>
            <source src={NavGuideVideoWebm} type="video/webm"/>
            <source src={NavGuideVideo} type="video/mp4"/>
          </video>
          <div className="btn" onClick={clickButton}>View Painting</div>
        </div>
      </div>
      <div className={ overlayHidden ? 'zoom' : 'zoom hide'}>
        <span id="zoom-in"></span>
        <span id="zoom-out"></span>
      </div>
      { experienceModal &&
        <div className="modal-overlay" onClick={e => !window.transitionLock && e.target.className.indexOf('modal-overlay') >= 0 && close()}>
          <Modal {...experienceModal} onClose={close} />
        </div>
      }
    </div>
  )
}

export default Experience
