import React, { useGlobal } from 'reactn'
import BGPhoto from '../assets/artist-photo.jpg'
import ModalPhoto from '../assets/full-length-artist-photo.jpg'
import Modal from './Modal'
import './Content.scss'

const items = [
  {
    src: BGPhoto,
    heading: 'About the Artist: Sam Spratt',
    description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Read More.',
    modal: {
      type: 'content-photo',
      content: ModalPhoto,
      heading: 'About Sam Spratt',
      description: `Sam Spratt is a Brooklyn-based illustrator who stumbled his way into the art world after a high school sweetheart persuaded him to attend art school. Since graduating from Savannah College of Art and Design in 2010, Sam has made quite the name for himself. What began as a few sketches on Tumblr turned into a lifelong career of creating intricate illustrations for world renowned celebrities and brands including Childish Gambino, Janelle Monae, Netflix, FX, Marvel, Rockstar Games, and 2k –– just to name a few. He has offered creative direction and created artwork covering magazines, newspapers, billboards, books, Broadway plays, albums, films, documentaries, TV shows, and ad campaigns alike. Sam is highly recognized for his skills, speed and deep attention to detail, garnering recognition by many in the industry who have witnessed his impressive masterpieces. Though he is versed in both traditional and digital illustration work, his fastidious sketch process is the key to his success. After extensive research and loose sketches, Sam uses his Cintiq and Photoshop to take a traditional skill set to digitally paint these spectacular, naturalistic images with a stylus and a computer. For this project, Sam was able to capture every essence of advertising's most iconic creatives and campaigns in an opulent rendition reminiscent of the renaissance era.`
    }
  },
]

function ContentBox (props) {
  const [ contentModal, setContantModal ] = useGlobal('contentModal')
  return (
    <div className="content-box">
      <div className="inner" onClick={() => setContantModal(props.modal)}>
        <div className="image" style={{ backgroundImage: `url("${props.src}")` }} />
        <div className="inner-content">
          <div className="inner-heading">About the Artist</div>
        </div>
      </div>
    </div>
  )
}

function Content () {
  const [ contentModal, setContentModal ] = useGlobal('contentModal')
  
  return (
    <div className="content">
      <div className="content-items">
        <ContentBox {...items[0]} />
      </div>
      { contentModal &&
        <div className="content-overlay" onClick={e => e.target.className.indexOf('content-overlay') >= 0 && setContentModal(null)}>
          <Modal {...contentModal} onClose={() => setContentModal(null)} />
        </div>
      }
    </div>
  )
}

export default Content
